import QuizLayout from '@components/layouts/quiz-layout';
import { styled } from '@mui/material';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import InputPassword from '@ui/input-password';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  isLoadingRegisterQuizUserSelector,
  quizUserSelector
} from '@redux/selectors';
import { createNewUserFromQuiz } from '@redux/actions';
import { navigate } from 'gatsby-link';
import { Loader } from '@components/helpers';
import { QuizUser } from '../types';

type FormData = {
  password: string;
  confirmPassword: string;
};

type QuizPasswordProps = {
  readonly quizUser: QuizUser;
  readonly isLoading: boolean;
  readonly createNewUserFromQuiz: (data: {
    userId: string;
    password: string;
  }) => void;
};

const Container = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  max-width: 448px;
`;

const Title = styled('h1')`
  color: var(--Pallets-Neutral-700, #484848);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 1rem;
`;

const Info = styled('p')`
  color: var(--Pallets-Neutral-700, #484848);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 1rem;
`;

const InfoTerms = styled('p')`
  color: var(--Pallets-Neutral-700, #484848);
  font-size: 0.75rem;
  font-weight: 400;
  text-align: center;
  line-height: 1.123rem;
  margin-top: 1rem;
`;

const mapStateToProps = createSelector(
  quizUserSelector,
  isLoadingRegisterQuizUserSelector,
  (quizUser: QuizUser, isLoading: boolean) => ({
    quizUser,
    isLoading
  })
);

const mapDispatchToProps = {
  createNewUserFromQuiz
};

const initialValues: FormData = {
  password: '',
  confirmPassword: ''
};

function QuizPassword({
  quizUser,
  isLoading,
  createNewUserFromQuiz
}: QuizPasswordProps) {
  const { control, watch, handleSubmit } = useForm<FormData>({
    defaultValues: initialValues
  });

  if (!quizUser) {
    void navigate('/quiz');
    return <Loader fullScreen={true} />;
  }

  const password = watch('password');

  const onSubmit = (data: FormData) => {
    if (!quizUser?.id) {
      return;
    }

    createNewUserFromQuiz({
      userId: quizUser.id,
      password: data.password
    });
  };

  return (
    <QuizLayout>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>Estamos quase lá...</Title>

          <Info>
            Para finalizar o seu cadastro, crie uma senha de acesso à plataforma
          </Info>

          <Controller
            control={control}
            name='password'
            rules={{
              required: {
                value: true,
                message: 'Campo obrigatório'
              },
              minLength: {
                value: 8,
                message: 'A senha deve ter pelo menos 8 caracteres'
              }
            }}
            render={({ field, fieldState }) => (
              <InputPassword
                placeholder='Senha'
                hasError={!!fieldState.error}
                messageError={fieldState.error?.message}
                {...field}
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />

          <Controller
            control={control}
            name='confirmPassword'
            rules={{
              required: {
                value: true,
                message: 'Campo obrigatório'
              },
              validate: (value: string) =>
                value === password || 'Senhas devem ser iguais'
            }}
            render={({ field, fieldState }) => (
              <InputPassword
                placeholder='Confirmar senha'
                hasError={!!fieldState.error}
                messageError={fieldState.error?.message}
                {...field}
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />

          <Button
            type='submit'
            buttonType={ButtonTypes.Primary}
            width='100%'
            isLoading={isLoading}
          >
            CRIAR CONTA
          </Button>

          <InfoTerms>
            De acordo com a Lei Geral de Proteção de Dados nº 13.709/18, você,
            titular de dados, manifesta sua concordância no tratamento dos dados
            pessoais fornecidos no preenchimento do cadastro para as finalidades
            previstas no termo de uso da plataforma.
          </InfoTerms>
        </Form>
      </Container>
    </QuizLayout>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizPassword);
